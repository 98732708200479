﻿#{headings(1,6)} {
    font-weight: 700;
    font-family: 'Gt walsheim', sans-serif;
}

ul {
    margin-left: 1.2rem;
    margin-bottom:0;

    li {
        margin-bottom: 0.8rem;

        &:last-child {
            margin-bottom: 0rem;
        }
    }
}